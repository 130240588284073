<template>
  <ServicesDescription
    :isPageWrapper="true"
    @connectService="connectService"
  >
    <template #title>
      Onlo Phone
    </template>
    <template #body>
      <p>OnLo Phone - приложение для компьютера для удобного и эффективного управления звонками.</p>
      <section>
        <ul>
          <li v-for="item in textList" :key="item">
            <the-list-item>
              <template #icon>
                <the-square></the-square>
              </template>
              {{ item }}
            </the-list-item>
          </li>
        </ul>
        <p :class="$style.imgWrap">
          <img :class="$style.img" src="@/assets/img_onlo_phone.svg" alt="Приложение Onlo Phone" />
        </p>
      </section>
      <p>
        Звоните с Вашего телефона в пару кликов на компьютере – из собственной адресной книги,
        журнала вызовов или скопировав нужный телефон в буфер. При входящем звонке на экране
        компьютера всплывает окно с определившимся номеров телефона и именем контакта из адресной
        книги.
      </p>
      <br />
      <p>
        Отправьте заявку на подключение опции и наш специалист позвонит Вам для консультации и
        уточнения технической возможности.
      </p>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import TheListItem from '@/components/shared/TheListItem.vue';
import TheSquare from '@/components/shared/marks/TheSquare.vue';

export default {
  data() {
    return {
      textList: [
        'История звонков,',
        'Телефонная книга контактов',
        'Уведомления о вызовах',
        'Звонок по номеру из буфера обмена (CTRL+C)',
        'Интеграция с Виртуальной АТС'
      ]
    };
  },

  components: {
    ServicesDescription,
    TheSquare,
    TheListItem
  },

  methods: {
    connectService() {
      this.$router.push({
        path: '/create-statement',
        query: { st: 'addService', service: 'менеджер-контактов-onlo-phone' }
      });
    }
  }
};
</script>

<style module>
.imgWrap {
  text-align: center;
}

.img {
  width: 100%;
}

.labels {
  font-weight: 600;
}

@media (min-width: 576px) {
  .img {
    width: auto;
  }
}

@media (min-width: 992px) {
  .formWrap {
    max-width: 38%;
  }
}

@media (min-width: 1200px) {
  .formWrap {
    max-width: 20%;
  }
}
</style>
