<template>
  <div :class="$style.square"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" module>
.square {
  width: 0.75rem;
  height: 0.75rem;
  border: 3px solid $red;
  margin-right: 0.25rem;
}
</style>
